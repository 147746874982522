import { config } from '../../config.ts';

export const getAllFeatureSegments = async () => {
    try {
        const response = await fetch(config.featurevisorAllFeaturesUrl);
        const featureObj = await response.json();
        return featureObj?.segments;
    } catch (e) {
        console.error(e);
    }
};
