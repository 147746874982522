import { useIntl } from 'react-intl';
import { useUploadInputFile } from '../hooks/useUploadInputFile.tsx';
import { useCallback, useRef, useState } from 'react';
import { parseLoadFile } from '../utils/parseLoadFile.ts';
import { InfoIcon } from '@trawa-energy/ui-kit';
import classNames from 'classnames';
import { trpcOffertool as trpc } from '../../utils/trpc.ts';

export const LoadFileComponent = ({
    hasLoadFile,
    dealId,
    disabled,
    refetchDeal,
}: {
    hasLoadFile: boolean;
    dealId: string;
    disabled?: boolean;
    refetchDeal: () => void;
}) => {
    const intl = useIntl();

    const { isUploading, error: isUploadError, upload } = useUploadInputFile({ dealId, fileName: 'load.xlsx' });
    const [hasParseError, setHasParseError] = useState<{ error: Error; success: boolean } | undefined>();
    const fileRef = useRef<HTMLInputElement>(null);

    const { mutateAsync: updateLoadFileName } = trpc.deals.updateDeal.useMutation();

    const getUploadParseErrorInfo = (error: Error) => {
        const amount = error.message.match(/Found (\d+) invalid/);
        let row = error.message.match(/index: (\d+)/);

        if (amount && row) {
            return intl.formatMessage(
                { id: 'deals.consumptionAnalysis.loadProfile.error.parseErrorMany' },
                { amount: amount[1], row: Number(row[1]) + 2 } /* adding 2 for header and index starting 0 */,
            );
        }

        if (!row && amount) {
            row = error.message.match(/Indexes are: ([\d,\s]+)/); /* unlikely case that only <= 5 errors */
            if (row) {
                const newIndexes = row[1]
                    .split(',')
                    .map(i => Number(i) + 2)
                    .join(', ');
                return intl.formatMessage(
                    { id: 'deals.consumptionAnalysis.loadProfile.error.parseErrorFew' },
                    { amount: amount[1], row: newIndexes },
                );
            }
        }

        return intl.formatMessage(
            { id: 'deals.consumptionAnalysis.loadProfile.error.parseErrorFew' },
            { amount: amount?.[1] ?? '', row: 'unknown' },
        );
    };

    const errorWrapper = isUploadError
        ? {
              title: intl.formatMessage({ id: 'deals.consumptionAnalysis.loadProfile.error.loadProfile' }),
              error: isUploadError,
          }
        : hasParseError
          ? {
                title: getUploadParseErrorInfo(hasParseError.error),
                error: hasParseError.error,
            }
          : undefined;

    const handleUploadLoadProfile = useCallback(
        async (event: React.ChangeEvent<HTMLInputElement>) => {
            setHasParseError(undefined);
            const file = event.currentTarget.files?.[0];
            if (!file) {
                return;
            }

            const parseResult = await parseLoadFile(file);
            if (!parseResult.success) {
                setHasParseError(parseResult);
                if (fileRef.current) {
                    fileRef.current.value = '';
                }
                return;
            }

            await upload(file, {
                onSuccess: async () => {
                    await updateLoadFileName({ dealId, loadFileName: file.name });
                    await refetchDeal();
                    if (fileRef.current) {
                        fileRef.current.value = '';
                    }
                },
            });
        },
        [upload, refetchDeal, updateLoadFileName, dealId],
    );

    return (
        <>
            <div>
                {errorWrapper && (
                    <div role="alert" className="alert alert-warning p-1.5 my-4">
                        <InfoIcon />
                        <span>{errorWrapper.title}</span>
                    </div>
                )}
                <label className="flex join">
                    <span
                        className={classNames('btn btn-neutral btn-sm join-item', {
                            'btn-disabled': isUploading || disabled,
                        })}
                    >
                        {hasLoadFile
                            ? intl.formatMessage({ id: 'deals.consumptionAnalysis.loadProfile.buttonHasLoadFile' })
                            : intl.formatMessage({
                                  id: 'deals.consumptionAnalysis.loadProfile.buttonHasNoLoadFile',
                              })}{' '}
                    </span>
                    <input
                        ref={fileRef}
                        disabled={isUploading || disabled}
                        type="file"
                        className={classNames(
                            'file-input file-input-bordered file-input-sm focus:outline-0 join-item [&::file-selector-button]:hidden pl-2.5',
                            { 'input-disabled': isUploading },
                        )}
                        accept=".xlsx"
                        onChange={handleUploadLoadProfile}
                    />
                </label>
            </div>
        </>
    );
};
