import React, { useEffect, useRef } from 'react';
import { Loader } from '@googlemaps/js-api-loader';
import { config } from '../../config.ts';
import { useIntl } from 'react-intl';
import { validateAddress } from '../utils/validateAddress.ts';
import classNames from 'classnames';

const loader = new Loader({
    apiKey: config.googleMapsApiKey,
    version: 'weekly',
    libraries: ['places'],
    region: 'de',
});

const Autocomplete = ({
    onPlaceSelected,
    disabled,
    hasError,
}: {
    disabled: boolean;
    hasError?: boolean;
    onPlaceSelected: (
        place: google.maps.places.PlaceResult,
        validatedAddress: ReturnType<typeof validateAddress>,
    ) => void;
}) => {
    const intl = useIntl();
    const autocompleteRef = useRef<google.maps.places.Autocomplete | null>(null);
    const inputRef = useRef<HTMLInputElement | null>(null);

    useEffect(() => {
        if (loader && inputRef?.current) {
            loader.importLibrary('places').then(() => {
                autocompleteRef.current = new window.google.maps.places.Autocomplete(inputRef.current!, {
                    componentRestrictions: { country: 'de' },
                    types: ['address'],
                });

                autocompleteRef.current.addListener('place_changed', () => {
                    const place = autocompleteRef.current?.getPlace();
                    if (place?.address_components) {
                        const validatedAddress = validateAddress(place.address_components);
                        onPlaceSelected(place, validatedAddress);
                    }
                });
            });
        }
    }, [onPlaceSelected]);

    return (
        <input
            disabled={disabled}
            ref={inputRef}
            className={classNames('input input-bordered input-md w-full', hasError && 'input-error')}
            placeholder={intl.formatMessage({ id: 'deals.consumptionAnalysis.fees.address.placeholder' })}
        />
    );
};

export default Autocomplete;
