import z from 'zod';

const booleanStringSchema = z.union([z.literal('true'), z.literal('false')]);

const envSchema = z.object({
    VITE_ENVIRONMENT: z.string(),
    VITE_BACKOFFICE_API_BASE_URL: z.string().url(),
    VITE_OFFER_TOOL_API_BASE_URL: z.string().url(),
    VITE_AWS_COGNITO_REGION: z.string(),
    VITE_AWS_USER_POOLS_ID: z.string(),
    VITE_AWS_USER_POOLS_WEB_CLIENT_ID: z.string(),
    VITE_OFFER_DASHBOARD_BASE_URL: z.string().url(),
    VITE_PORTAL_BASE_URL: z.string().url(),
    VITE_HUBSPOT_DEAL_APP_INSTALLER_URL: z.string().url(),
    VITE_GOOGLE_MAPS_API_KEY: z.string(),
    VITE_SENTRY_DSN: z.string(),
    VITE_SENTRY_ENABLED: booleanStringSchema.optional(),
    VITE_MIXPANEL_PROJECT_TOKEN: z.string().optional(),
    VITE_MIXPANEL_ENABLED: booleanStringSchema.optional(),
    VITE_FEATUREVISOR_OFFER_TOOL: z.string().url(),
    VITE_FEATUREVISOR_PORTAL: z.string().url(),
});

const parsedEnvResult = envSchema.safeParse(import.meta.env);

if (!parsedEnvResult.success) {
    throw new Error(`Invalid config. Please check env vars.` + parsedEnvResult.error.errors.join('\n'));
}

const parsedEnv = parsedEnvResult.data;

export const config = {
    environment: parsedEnv.VITE_ENVIRONMENT,
    clientsApiBaseUrl: parsedEnv.VITE_BACKOFFICE_API_BASE_URL,
    clientOfferToolApiBaseUrl: parsedEnv.VITE_OFFER_TOOL_API_BASE_URL,
    offerDashboardBaseUrl: parsedEnv.VITE_OFFER_DASHBOARD_BASE_URL,
    hubspotDealAppInstallerUrl: parsedEnv.VITE_HUBSPOT_DEAL_APP_INSTALLER_URL,
    portalBaseUrl: parsedEnv.VITE_PORTAL_BASE_URL,
    googleMapsApiKey: parsedEnv.VITE_GOOGLE_MAPS_API_KEY,
    sentryDsnToken: parsedEnv.VITE_SENTRY_DSN,
    sentryEnabled: parsedEnv.VITE_SENTRY_ENABLED ?? false,
    amplifyAuth: {
        aws_cognito_region: parsedEnv.VITE_AWS_COGNITO_REGION,
        aws_user_pools_id: parsedEnv.VITE_AWS_USER_POOLS_ID,
        aws_user_pools_web_client_id: parsedEnv.VITE_AWS_USER_POOLS_WEB_CLIENT_ID,
        aws_mandatory_sign_in: 'enable',
    },
    mixpanelProjectToken: parsedEnv.VITE_MIXPANEL_PROJECT_TOKEN,
    mixpanelEnabled: parsedEnv.VITE_MIXPANEL_ENABLED ?? false,
    featurevisorUrl: parsedEnv.VITE_FEATUREVISOR_OFFER_TOOL,
    featurevisorAllFeaturesUrl: parsedEnv.VITE_FEATUREVISOR_PORTAL,
};
