export type FeatureSegment = {
    key: string;
    conditions: string;
};

export const mapFeaturesToAccounts = ({ accountId, features }: { accountId: string; features: FeatureSegment[] }) => {
    const featuresList = features.map(feature => {
        const ids = JSON.parse(feature.conditions);
        return {
            name: feature.key,
            ids: ids[0].value,
        };
    });
    return featuresList.reduce(
        (accountFeatures: Record<string, string[]>, { ids, name }: { ids: string[]; name: string }) => {
            if (ids.includes(accountId)) {
                if (accountFeatures[accountId]) {
                    accountFeatures[accountId].push(name);
                } else {
                    accountFeatures[accountId] = [name];
                }
            }
            return accountFeatures;
        },
        {},
    );
};
