import { Contract, Presentation } from '../types.ts';

export const getOneOfEachBySpot = (items: Contract[] | Presentation[]) => {
    const itemsBySpot =
        items?.reduce<Record<string, Presentation[] | Contract[]>>((acc, item) => {
            if (!acc[item.spotOption]) {
                acc[item.spotOption] = [];
            }
            acc[item.spotOption].push(item);
            return acc;
        }, {}) ?? {};

    const filteredItems = Object.values(itemsBySpot).flatMap((spotItems): Contract[] => {
        const runningItems = spotItems.filter(item => item.status === 'running') ?? [];
        const successOrFailedItems = spotItems.find(item => item.status === 'success' || item.status === 'failed');
        return successOrFailedItems ? [...runningItems, successOrFailedItems] : runningItems;
    });

    return filteredItems;
};
