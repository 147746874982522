import { trpcOffertool as trpc } from '../../utils/trpc.ts';
import { useParams } from 'react-router-dom';
import { CustomerConfig } from '@trawa-energy/offer-tool-api/deals/schemas';
import { useEffect, useState } from 'react';

const emptyV2Config: CustomerConfig = {
    version: { customer_config_version: 'v2.5' },
    offer_name: '',
    contract_settings: {
        start_date_year: 2025,
        duration_years: 1,
        sector: 'industry',
        number_of_market_locations: 0,
        is_external_offer: false,
    },
    core_opt_settings: {
        maximum_fixed: 0.8,
        onsite_reduction_activate: false,
        onsite_solar: 0,
    },
    prices: {
        trawa_internal_fee: 0,
        distribution_fee: 0,
        goo_price: 0.003,
        monthly_base_fee: 0,
        ppa_price_wind_raw: 0,
        ppa_price_solar_raw: 0,
        ppa_trade_margin: 0,
        price_base_years: [],
        price_peak_years: [],
        price_levies: 0,
        price_electricity_tax: 0,
        price_grid_fees: 0,
        price_licence_fees: 0,
    },
    opt_settings: {
        single_offer_mode: { activate: false, spot_exposure_single_offer_mode: 0 },
        maxima: { wind: null, solar: null, base: null, peak: null, offpeak: null },
    },
};

export const useCustomConfig = () => {
    const { dealId } = useParams();
    const [config, setConfig] = useState<CustomerConfig | undefined>(undefined);
    const { data, isLoading, isError, refetch } = trpc.deals.getRawCustomerConfig.useQuery(
        {
            dealId: dealId!,
            format: 'json',
        },
        {
            enabled: !!dealId,
        },
    );

    useEffect(() => {
        if (data) {
            if (data && data?.version && !data?.version?.customer_config_version.startsWith('v2.5')) {
                setConfig(emptyV2Config);
            } else {
                setConfig(data);
            }
        }
    }, [data, config]);

    return { config, isConfigLoading: isLoading, isConfigError: isError, refetch };
};
